<template>
    <el-row :gutter="160">
        <el-col :span="2">
            <el-button type="primary" icon="el-icon-plus" @click="onClickAdd">添加新帐号</el-button>
        </el-col>
        <el-col :span="24">
            <el-table ref="multipleTable" :data="userList" :height="tableHeight" border style="width: 100%"
                :row-class-name="tableRowClassName" :cell-style="{padding:4+'px'}" class="serverlist">
                <el-table-column prop="id" label="id" width="60"> </el-table-column>
                <el-table-column prop="username" label="帐号" show-overflow-tooltip></el-table-column>
                <el-table-column prop="desc" label="备注" show-overflow-tooltip></el-table-column>
                <el-table-column prop="loginip" label="登录IP" show-overflow-tooltip></el-table-column>
                <el-table-column prop="logintime" label="登录时间" show-overflow-tooltip></el-table-column>
                <el-table-column prop="createtime" label="创建时间" show-overflow-tooltip></el-table-column>
                <el-table-column label="状态" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <span v-if="scope.row.state===0" style="color: #FF0000;">停用</span>
                        <span v-if="scope.row.state===1" style="color: #00D000;">正常</span>
                    </template>
                </el-table-column>               
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onClickEditUser(scope.row)" type="primary" icon="el-icon-edit"
                            size="small" :disabled="1==scope.row.id">修改</el-button>
                        <el-button @click.native.prevent="onClickDelUser(scope.row)" :type="scope.row.state?'danger':'success'" icon="el-icon-delete"
                            size="small" :disabled="1==scope.row.id">{{scope.row.state?"停用":"启用"}}</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-col>
        <!-- ========弹框BEGIN====== -->
        <el-dialog :title="formTitle" :visible.sync="dialogForm" width="40%" :close-on-click-modal="false" center
            class="formparams">
            <el-form size=small :model="form">
                <el-form-item label="登录帐号" :label-width="formLabelWidth">
                    <el-row>
                        <el-col :span="8">
                            <el-form-item label="帐号" label-width="60px">
                                <el-input v-model="form.username" placeholder="登录帐号"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="输入密码" label-width="80px" v-if="0==form.id">
                                <el-input v-model="form.password1" placeholder="登录密码"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="8">
                            <el-form-item label="重复密码" label-width="80px" v-if="0==form.id">
                                <el-input v-model="form.password2" placeholder="确认密码"></el-input>
                            </el-form-item>
                        </el-col>                        
                    </el-row>
                </el-form-item>
                <el-form-item label="功能权限" :label-width="formLabelWidth">
                    <el-checkbox v-model="operateItems.examineMail">审批</el-checkbox>
                    <el-checkbox v-model="operateItems.examinePlayer">玩家管理</el-checkbox>
                    <el-checkbox v-model="operateItems.examineEvent">埋点编辑</el-checkbox>
                </el-form-item>                    
                <el-form-item label="游戏权限" :label-width="formLabelWidth">
                    <el-tree :data="games" ref="treeGame" show-checkbox node-key="id" 
                        :default-expanded-keys="[0]" :default-checked-keys="checkGames"
                        :props="defaultProps" v-if="dialogForm">
                    </el-tree>
                </el-form-item>
                <el-form-item label="功能权限" :label-width="formLabelWidth">
                    <el-tree :data="menus" ref="treeMenu" show-checkbox node-key="id"
                        :default-expanded-keys="['GAME_OPERATE']" :default-checked-keys="checkMenus"
                        :props="defaultProps" v-if="dialogForm">
                    </el-tree>
                </el-form-item>
                <el-form-item label="备注" :label-width="formLabelWidth">
                    <el-input v-model="form.desc" placeholder="备注信息"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onAddSystemUser">确 定</el-button>
                <el-button icon="el-icon-close" @click="dialogForm = false">取 消</el-button>
            </div>
        </el-dialog>
        <!-- ========弹框END====== -->
    </el-row>
</template>

<script>
    import md5 from 'js-md5'
    import {
        mapGetters
    } from 'vuex'

    import gamesCfg from '../../games.js'
    import menuCfg from '../../submenu.js'

    export default {
        data() {
            return {
                userList: [],
                formTitle: '',
                dialogForm: false,
                form: {
                    id: '',
                    username: '',
                    role: '',
                    password1: '',
                    password2: '',                    
                    desc: '',
                },
                ////
                games: [], // 游戏权限项
                menus: [], // 功能权限项
                operateItems: {// 操作权限项
                    examineMail: false, // 审批
                    examinePlayer: false,   // 玩家管理
                    examineEvent: false, // 事件点录入
                },
                checkGames: [],
                checkMenus: [],
                defaultProps: {
                    children: 'children',
                    label: 'label'
                },
                userRoles: [{value: 1, label: "运营"}, {value: 2,label: "策划"},{value: 3,label: "程序"}],
                formLabelWidth: '130px',
                tableHeight: document.documentElement.clientHeight-136,
            }
        },
        created: function() {
            this.refreshSystemUserList();
            // 加载游戏列表项
            this.loadPermissionOptions();
        },
        computed: {
            ...mapGetters(
                ['centerHttp']
            ),
        },
        methods: {
            // 刷新列表
            refreshSystemUserList() {
                this.centerHttp(103, {}).then((response) => {
                    this.userList = response.data.list
                })
            },
            // 权限列表选项生成
            loadPermissionOptions() {
                // 游戏项
                this.games.push({ id: 0, label: '全部', children: [] });
                for (let i = 0; i < gamesCfg.list.length; i++) {
                    let cfg = gamesCfg.list[i];
                    this.games[0].children.push({
                        id: cfg.value,
                        label: cfg.label,
                    });
                }
                // 加载功能列表项
                for (let k in menuCfg) {
                    let cfg = menuCfg[k];
                    // 模块区
                    let node = {
                        id: k,
                        label: cfg.label,
                        children: []
                    };
                    // 功能区
                    for (let i = 0; i < cfg.subs.length; i++) {
                        node.children.push({
                            id: cfg.subs[i].path.substr(1),
                            label: cfg.subs[i].label,
                        });
                    }
                    // 禁用主页勾选(全部人员均有主页权限)
                    if ('GAME_INDEX' == k) {
                        node.disabled = true;
                        node.children = [];
                    }
                    this.menus.push(node);
                }
            },
            // 弹出新增参数编辑框
            onClickAdd() {
                this.formTitle = '添加新帐号'
                this.dialogForm = true
                for (let k in this.form) {
                    this.form[k] = ''
                }
                // 新增时，初始下权限选择 (el-tree 需设置v-if，否则不会刷新)
                this.checkGames = [0];
                this.checkMenus = ['GAME_INDEX', 'GAME_OPERATE', 'GAME_CONFIG', 'GAME_SERVER'];
                this.operateItems.examineMail = false;
                this.operateItems.examinePlayer = false;
                this.operateItems.examineEvent = false;
            },
            // 弹出修改参数编辑框
            onClickEditUser(row) {
                this.formTitle = '修改帐号'
                this.dialogForm = true
                this.form = JSON.parse(JSON.stringify(row))
                // 修改时，显示已选择的权限 (el-tree 需设置v-if，否则不会刷新)
                this.checkGames = row.games;
                this.checkMenus = row.menus;
                this.checkMenus.push('GAME_INDEX');
                this.operateItems.examineMail = false;
                this.operateItems.examinePlayer = false;
                this.operateItems.examineEvent = false;
                for (let i=0; i<row.operations.length; i++) {
                    if ("mail" == row.operations[i]) {
                        this.operateItems.examineMail = true;
                    }
                    if ("player" == row.operations[i]) {
                        this.operateItems.examinePlayer = true;
                    }
                    if ("event" == row.operations[i]) {
                        this.operateItems.examineEvent = true;
                    }                    
                }
            },
            // 弹出删除确认框
            onClickDelUser(row) {
                let opstr = row.state ? "停用" : "启用";
                this.$confirm('是否' + opstr + '[ ' + row.username + ' ]用户?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.centerHttp(104, {
                        id: row.id
                    }).then((response) => {
                        if (0 == response.data.errcode) {
                            this.$message.success("提交成功")
                            this.refreshSystemUserList()
                        }
                    })
                }).catch(() => {})
            },
            // 添加系统用户
            onAddSystemUser() {
                let rqs = {id: this.form.id, username: this.form.username};
                if (0 == rqs.username.length) {
                    this.$message.error('登录帐号不能为空');
                    return;
                }
                if (0 == rqs.id) {
                    if (this.form.password1.length<6 || this.form.password1.length>20) {
                        this.$message.error('登录密码请设置6位以上, 20位以下');
                        return;
                    }
                    if (this.form.password1 != this.form.password2) {
                        this.$message.error('两次输入的密码不一致');
                        return;
                    }
                    rqs.password = md5(this.form.password1);
                }
                rqs.games = this.$refs.treeGame.getCheckedKeys(true);
                if (0 == rqs.games.length) {
                    this.$message.error('请至少分配一个游戏的权限');
                    return;
                }
                rqs.menus = this.$refs.treeMenu.getCheckedKeys(true);
                for (let i = 0; i < rqs.menus.length; i++) { // 删除GAME_INDEX
                    if ('GAME_INDEX' == rqs.menus[i]) {
                        rqs.menus.splice(i, 1);
                        break;
                    }
                }
                rqs.operations = [];
                if (this.operateItems.examineMail) {
                    rqs.operations.push("mail");
                }
                if (this.operateItems.examinePlayer) {
                    rqs.operations.push("player");
                }
                if (this.operateItems.examineEvent) {
                    rqs.operations.push("event");
                }                
                this.dialogForm = false
                // 请求操作
                rqs.games = JSON.stringify(rqs.games);
                rqs.menus = JSON.stringify(rqs.menus);
                rqs.operations = JSON.stringify(rqs.operations);
                rqs.desc = this.form.desc;
                this.centerHttp(102, rqs).then((response) => {
                    if (0 == response.data.errcode) {
                        this.$message.success('提交成功');
                        this.refreshSystemUserList();
                    } else {
                        this.$message.error('系统错误');
                    }
                })
            },
            // 数据表色彩间隔效果     
            tableRowClassName({
                rowIndex
            }) {
                if (0 == rowIndex % 2) {
                    return 'warning-row';
                } else {
                    return 'success-row';
                }
            },
        },
    }
</script>

<style>
    .serverlist.el-table .warning-row {
        background: #f5f7fa;
    }

    .serverlist.el-table .success-row {
        background: #ffffff;
    }

    .serverlist.el-table {
        margin-top: 16px;
    }

    .serverlist {
        font-size: 12px;
    }
</style>
